import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button } from "react-bootstrap";

const TargetGame = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [score, setScore] = useState(0);
  const [targetPosition, setTargetPosition] = useState({ x: 0, y: 0 });
  const [timeLeft, setTimeLeft] = useState(20);
  const gameIntervalRef = useRef(null);
  const gameContainerRef = useRef(null); // Reference to the game container

  // Memoized version of moveTarget
  const moveTarget = useCallback(() => {
    if (isPlaying && gameContainerRef.current) {
      const containerWidth = gameContainerRef.current.offsetWidth;
      const containerHeight = gameContainerRef.current.offsetHeight;
      const targetSize = 50; // Assuming target size is 50x50

      const x = Math.random() * (containerWidth - targetSize);
      const y = Math.random() * (containerHeight - targetSize);

      setTargetPosition({ x, y });
    }
  }, [isPlaying]);

  const startGame = () => {
    setIsPlaying(true);
    setScore(0);
    setTimeLeft(20);
    moveTarget();
    gameIntervalRef.current = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          endGame();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const endGame = () => {
    setIsPlaying(false);
    clearInterval(gameIntervalRef.current);
    gameIntervalRef.current = null;
  };

  const handleClickTarget = () => {
    if (isPlaying) {
      setScore((prev) => prev + 1);
      moveTarget();
    }
  };

  useEffect(() => {
    const moveInterval = setInterval(() => {
      if (isPlaying) moveTarget();
    }, 1000);

    return () => clearInterval(moveInterval);
  }, [isPlaying, moveTarget]);

  return (
    <div className="game-container" ref={gameContainerRef}>
      {/* Show "Try to click on the target" message and "Start" button only before the game starts */}
      {!isPlaying && score === 0 && (
        <div className="start-screen">
          <h3>Try to click the target! 🎯</h3>
          <Button variant="outline-dark" onClick={startGame}>
            Start
          </Button>
        </div>
      )}
      {/* Show the target, score, and time only during the game */}
      {isPlaying && (
        <>
          <div
            className="target"
            style={{
              left: `${targetPosition.x}px`,
              top: `${targetPosition.y}px`,
            }}
            onClick={handleClickTarget}
          ></div>
          <div className="score">
            <h4>Score: {score}</h4>
            <h4>Time Left: {timeLeft}s</h4>
          </div>
        </>
      )}
      {/* Show "Game Over" message and "Play Again" button only after the game ends */}
      {!isPlaying && score > 0 && (
        <div className="game-over">
          <h2>Game Over!</h2>
          <h4>Your Score: {score}</h4>
          <Button variant="outline-dark" onClick={startGame}>
            Play Again
          </Button>
        </div>
      )}
    </div>
  );
};

export default TargetGame;
