export const redditReducer = (state, action) => {
  switch (action.type) {
    case "loading":
      return { ...state, loading: true, topic: action.payload };

    case "success":
      return {
        ...state,
        loading: false,
        redditData: action.payload.answer.data,
        query: action.payload.answer.query,
        nOfPosts: action.payload.posts,
        nOfComments: action.payload.comments,
        nOfReplies: action.payload.replies,
        error: null,
      };

    case "failure":
      return { loading: false, error: action.payload };

    default:
      return { state };
  }
};
