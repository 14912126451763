import React, { useReducer } from "react";
import { Col, Row, Alert } from "react-bootstrap";
import Explainer from "../components/Explainer";
import TopicForm from "../components/TopicForm";
import { redditReducer } from "../reducers/redditReducer";
import { redditAction } from "../actions/redditAction";
import Markdown from "markdown-to-jsx";
import TargetGame from "../components/TargetGame";

const Home = () => {
  const initialState = { loading: false, redditData: [] };
  const [state, dispatch] = useReducer(redditReducer, initialState);

  async function getReddit(topic, timeframe) {
    dispatch({ type: "loading", payload: topic });
    try {
      const redditData = await redditAction(topic, timeframe);
      dispatch({ type: "success", payload: redditData });
    } catch (error) {
      dispatch({
        type: "failure",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }

  const calculateTimeSaved = (numberOfPosts) => {
    const reviewTimePerPost = 2; // in minutes
    return numberOfPosts * reviewTimePerPost; // Total time saved in minutes
  };

  return (
    <>
      {state.loading ? (
        <Row
          className="justify-content-md-center align-items-center"
          style={{ height: "100vh", marginTop: "-50px" }}
        >
          <Col className="d-flex flex-column align-items-center">
            <h5 className="typewriter mb-5">Loading..Doing Smart Things 🤓.</h5>
            {/* <DinoGame /> */}
            <TargetGame />
          </Col>
        </Row>
      ) : (
        <>
          {state.error ? <Alert variant="danger">{state.error}</Alert> : null}
          <Row>
            <Col>
              <Explainer />
            </Col>
          </Row>
          <Row>
            <TopicForm submit={getReddit} />
          </Row>

          {state.redditData && state.redditData.length > 1 ? (
            <>
              <Row className="my-5">
                <Alert variant="dark">
                  <Alert.Heading>
                    <p>{state.topic}</p>
                  </Alert.Heading>
                  <p>
                    {state.nOfPosts} Posts, {state.nOfComments} comments and{" "}
                    {state.nOfReplies} replies were analyzed.
                  </p>
                  <hr />
                  Time saved ⏱️: {calculateTimeSaved(state.nOfPosts)} minutes.
                  <hr />
                  <Markdown>{state.redditData}</Markdown>
                </Alert>
              </Row>
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default Home;
