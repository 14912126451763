import React from "react";
import { Alert } from "react-bootstrap";

const Explainer = () => {
  return (
    <>
      <Alert variant="light">
        <Alert.Heading>
          {" "}
          👋 Hey, want to know what people are saying?
        </Alert.Heading>
        <p>
          Stalking Reddit to save you time. We analyze hundreds of comments and
          threads to provide you answers. Just enter a topic, choose a timeframe
          and search!
        </p>
      </Alert>
    </>
  );
};

export default Explainer;
