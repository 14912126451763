import React, { useState } from "react";
import {
  Form,
  Button,
  InputGroup,
  DropdownButton,
  Dropdown,
  Col,
  Row,
} from "react-bootstrap";

const TopicForm = ({ submit }) => {
  const [topic, updateTopic] = useState("");
  const [timeframe, setTimeframe] = useState("all");

  const handleChange = (e) => {
    updateTopic(e.target.value);
  };

  const handleTimeframeChange = (eventKey) => {
    setTimeframe(eventKey);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    submit(topic, timeframe); // Submit both topic and timeframe
  };

  return (
    <Form onSubmit={submitHandler}>
      <Row className="mb-2">
        <Col>
          <InputGroup>
            <Form.Control
              type="text"
              placeholder="Enter a topic to start"
              value={topic}
              onChange={handleChange}
              aria-label="Topic"
            />
            <DropdownButton
              as={InputGroup.Append}
              variant="outline-secondary"
              title={timeframe.charAt(0).toUpperCase() + timeframe.slice(1)}
              onSelect={handleTimeframeChange}
            >
              <Dropdown.Item eventKey="all">All</Dropdown.Item>
              <Dropdown.Item eventKey="hour">Last hour</Dropdown.Item>
              <Dropdown.Item eventKey="day">Last day</Dropdown.Item>
              <Dropdown.Item eventKey="week">Last week</Dropdown.Item>
              <Dropdown.Item eventKey="month">Last month</Dropdown.Item>
            </DropdownButton>
            <Button
              type="submit"
              variant="outline-dark"
              className="input-group-append"
              disabled={topic.trim() === ""}
            >
              Discover
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <Form.Text className="text-muted mb-3">
        Need inspiration? Try Global Warming or Pineapple on Pizza
      </Form.Text>
    </Form>
  );
};

export default TopicForm;
