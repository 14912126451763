import React from "react";
import { Navbar, Container } from "react-bootstrap";

const Header = () => {
  return (
    <>
      <Navbar expand="lg" variant="light" bg="light" collapseOnSelect>
        <Container>
          <Navbar.Brand>
            <img
              alt=""
              src="./logo.png"
              width="60"
              height="60"
              className="d-inline-block align-center"
            />{" "}
            RedditEcho - AI Powered
          </Navbar.Brand>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
